import axios from 'axios'

// constantes
const dataInicial = {
    jobs: [],
    loading_jobs:[],
}

// types
const LOADING_JOBS = "LOADING_JOBS";
const GET_JOBS = 'GET_JOBS'

// reducer
export default function jobsReducer(state = dataInicial, action){
    switch(action.type){
        case LOADING_JOBS :
             return {...state,loading_jobs:true,};
        case GET_JOBS:
            return {
                ...state,
                jobs : action.payload
        };
        default:
            return state
    }
}


// actions
export const getJobsAction = () => async (dispatch, getState) => {
    dispatch({type: LOADING_JOBS})
    try {
        const res = await axios.get('https://mediacore.app/api/jobs/posiciones/activas/?client=15')
        dispatch({
            type: GET_JOBS,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

const serializeData = (data) => {
    const formData = new FormData()
    for (let key in data) {
      if (key === 'files') Array.from(data[key]).forEach((file) => formData.append(key, file))
      else
        formData.append(
          key,
          data[key] instanceof Blob || typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key]),
        )
    }
    return formData
}

export const sendJobsForm = (data) => async (dispatch, getState) => {
    try {
        await axios.post('https://mediacore.app/api/jobs/aplicaciones/aplicar/', 
        serializeData(data), {
            headers: {
                'Content-Type': 'application/json'
            },
        })
    } catch (error) {
        console.log(error)
    }
}