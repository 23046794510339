import axios from 'axios'

// constantes
const dataInicial = {
    name:'Laura Farías',
    short_name:'LauraFarias',
    base_url:'/',
    client_id:46,
    // base_url:'/clientes/agostina-filo/',
    social:
    {
        instagram:'',
        facebook:'',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['' , ''],
        whatsapp:{link:'',visual:''},
        mail:''
        
    },
    location:
    {
        address:"",
        region:'',
        cp:""
    },
    keys:{
        // captcha:'6Lc9VaAaAAAAANaHwR9avf7MCpVP7z019S-g_OMi', Mediahaus
        captcha:'6Lfmez4dAAAAAPRBJgBY1CDZNZIBBCks2ZaIy-M6',
        tokko:'',
        // tokko:'7d5dc24fcb137714860c3c5924cf3ac62acb3b9a',
        google_tgm:'',
        google_map:'',
        mediacore: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODMyZGJlYjQ2YjA3Yzk0OWFmYjFlZjcyMjdmOGUyMGUwZDU4NWRlMTQwMjRiZjYwNDI3ODZjMDc4MGYwZDdiZTNlMjNiOGYxNDMxMmMwZWUiLCJpYXQiOjE2MzUxODA1MTMsIm5iZiI6MTYzNTE4MDUxMywiZXhwIjoxNjY2NzE2NTEzLCJzdWIiOiIyOSIsInNjb3BlcyI6W119.G7y15Pvy53Y3USzw6BRTh38GmzR2YchXBum0WRwzbsy68TIDhIQ5IulSEG5RBUAd3yddBn6K4sb4fZfvqkfdKuBZua1dxzpC8ozNbfuPFtt8HR52Qy9avCdVY-MgyFukY2u7FmuMdBycLt2qQLfjrKNJtBfeS7DxDwmZunOK5z3QIXc3ekjfLhJaJpEntPT4C9NzfMpjkGkdmsxBXYV7M9jYgBDiB4UiMw85OcmqP_u7F2mqw9WMnD7r-tyusc2_sQxT8B_lzuVs7gP5Se1HindRBuC2kSOYTLguH0ymBfNeS7p6eRbWN8HkkwGrdWcvNvklq9U58C9kzBaiTS9W3gBjuuww3r4dOVYfDuHYvBdgkFy1CXrJmPYGZawyPaGhRLsE6UDBRmdbcuGu6ILjfvT0hJ-zkkBniRysiIPITA0COCeXjg1XdD70euuaVpv9RT0EU8LzM2G__i_Z-SX_Beg_40nrGXfMYgdgnRnAgpcYFaeK2VZBmsL9XWsek8MP_tLvRhOQSTkFMDEc6uviN6YRjzcPWm8GMAte-Bi9vv31C8lFosjzD07lUj-8jWAZqGdm23yquDfth9Njt2e4UNrSvxU5sJXrjpKeHy8RYs3OE-s6cMwQfNnmxVcc6o4S0TZEHfqFOYCewotvjFm1Ea47b-7oKhT-jbVo_2aXDVg",
    },
    seo:{
        title:'Laura Farías',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
        type:'website'
    },
    investor:{},
    modules: [],
}

const CHANGE_API = 'CHANGE_API'
const CHANGE_MAP = 'CHANGE_MAP'
const GET_INVESTOR = 'GET_INVESTOR'
const GET_CONFIGURATIONS = "GET_CONFIGURATIONS"

export default function settingsReducer(state = dataInicial, action){
    switch(action.type){
        case CHANGE_API : {
            return {...state,
                keys : {...state.keys,
                tokko: action.payload
                } 
            }
        }
        case CHANGE_MAP : {
            return {...state,
                keys : {...state.keys,
                google_map: action.payload
                } 
            }
        }
        case GET_INVESTOR : {
            return {...state,
                investor: action.payload
            }
        }
        case GET_CONFIGURATIONS: {
            return { ...state, 
                modules: action.payload 
            }
        }
        default:
            return state
    }
}

// 2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b

export const CHANGE_APIKEY_ACTION = (api_key) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_API,
        payload: api_key
    })
}
export const CHANGE_MAPKEY_ACTION = (map_key) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_MAP,
        payload: map_key
    })
}
export const GET_INVESTOR_ACTION = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`https://mediacore.app/api/investor/configuracion_usuarios/publica/?client=15`)
        dispatch({
            type: GET_INVESTOR,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}
export const GET_CONFIGURATIONS_ACTION = () => async (dispatch, getState) => {
    try {
      const res = await axios.get(`https://mediacore.app/api/core/custom-login/configuraciones/?client=15`)
      dispatch({
        type: GET_CONFIGURATIONS,
        payload: res.data,
      })
    } catch (error) {
      console.log(error)
    }
}