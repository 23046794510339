exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alquiler-js": () => import("./../../../src/pages/alquiler.js" /* webpackChunkName: "component---src-pages-alquiler-js" */),
  "component---src-pages-alquiler-temporario-js": () => import("./../../../src/pages/alquiler-temporario.js" /* webpackChunkName: "component---src-pages-alquiler-temporario-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-emprendimientos-[id]-js": () => import("./../../../src/pages/emprendimientos/[id].js" /* webpackChunkName: "component---src-pages-emprendimientos-[id]-js" */),
  "component---src-pages-emprendimientos-index-js": () => import("./../../../src/pages/emprendimientos/index.js" /* webpackChunkName: "component---src-pages-emprendimientos-index-js" */),
  "component---src-pages-emprendimientosold-js": () => import("./../../../src/pages/emprendimientosold.js" /* webpackChunkName: "component---src-pages-emprendimientosold-js" */),
  "component---src-pages-favoritos-js": () => import("./../../../src/pages/favoritos.js" /* webpackChunkName: "component---src-pages-favoritos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-novedades-[id]-[slug]-js": () => import("./../../../src/pages/novedades/[id]/[slug].js" /* webpackChunkName: "component---src-pages-novedades-[id]-[slug]-js" */),
  "component---src-pages-novedades-index-js": () => import("./../../../src/pages/novedades/index.js" /* webpackChunkName: "component---src-pages-novedades-index-js" */),
  "component---src-pages-pelicula-js": () => import("./../../../src/pages/pelicula.js" /* webpackChunkName: "component---src-pages-pelicula-js" */),
  "component---src-pages-propiedad-[operation]-[id]-js": () => import("./../../../src/pages/propiedad/[operation]/[id].js" /* webpackChunkName: "component---src-pages-propiedad-[operation]-[id]-js" */),
  "component---src-pages-propiedads-js": () => import("./../../../src/pages/propiedads.js" /* webpackChunkName: "component---src-pages-propiedads-js" */),
  "component---src-pages-shulbius-js": () => import("./../../../src/pages/shulbius.js" /* webpackChunkName: "component---src-pages-shulbius-js" */),
  "component---src-pages-ssr-js": () => import("./../../../src/pages/ssr.js" /* webpackChunkName: "component---src-pages-ssr-js" */),
  "component---src-pages-sumate-js": () => import("./../../../src/pages/sumate.js" /* webpackChunkName: "component---src-pages-sumate-js" */),
  "component---src-pages-tasaciones-js": () => import("./../../../src/pages/tasaciones.js" /* webpackChunkName: "component---src-pages-tasaciones-js" */),
  "component---src-pages-tokko-[id]-js": () => import("./../../../src/pages/tokko/[id].js" /* webpackChunkName: "component---src-pages-tokko-[id]-js" */),
  "component---src-pages-venta-js": () => import("./../../../src/pages/venta.js" /* webpackChunkName: "component---src-pages-venta-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

